//Utilities
import React from 'react'

//Images and Icons
import { FaBinoculars, FaChartLine, FaCode, FaBriefcaseMedical } from 'react-icons/fa'

function Features() {
  return (
    <>
      {/* Features */}
      <section id="features" className="py-16 bg-white-txt-bg bg-cover">
          <div className="container p-6 mx-auto">
              <p className='text-center text-sm text-gray-600 uppercase'>Welcome to</p>
              <h2 className="m-2 text-4xl font-bold text-center underline decoration-platyGold">Platypus</h2>
              <p className="max-w-md mx-auto text-center text-2xl md:max-w-2xl">Algorithmovigilance Monitoring and Operations System</p>
          </div>

          <div className="container mt-10 mx-auto">
              <div className="grid lg:grid-cols-4 text-center">
                  <div className="p-8">
                      <FaBinoculars className='text-platyViolet text-7xl mx-auto' />
                      <h3 className='text-xl font-bold mt-6 mb-6'>Model metadata collection and centralization</h3>
                  </div>
                  <div className="p-8">
                      <FaCode className='text-platyViolet text-7xl mx-auto' />
                      <h3 className='text-xl font-bold mt-6 mb-6'>Multiple model performance metrics continuously monitored</h3>
                  </div>
                  <div className="p-8">
                      <FaChartLine className='text-platyViolet text-7xl mx-auto' />
                      <h3 className='text-xl font-bold mt-6 mb-6'>Tracking dashboard for easy model surveillance</h3>
                  </div>
                  <div className="p-8">
                      <FaBriefcaseMedical className='text-platyViolet text-7xl mx-auto' />
                      <h3 className='text-xl font-bold mt-6 mb-6'>Generated alerts and set to model contacts for potential concerns</h3>
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default Features